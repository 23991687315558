import {
  colors,
  mediaQueries,
  size,
  typography,
  Container,
} from '@everlywell/leaves';
import styled from 'styled-components';
import checkMarkBullet from '../../../components/PDP/DetailsSection/images/check.svg';

export const SectionWrapper = styled.section`
  padding-top: ${size.xl3}px;

  ${mediaQueries.forTabletHorizontalUp} {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    gap: ${size.xl6}px;
    margin: 0 auto;
    padding-bottom: ${size.xl3}px;
  }

  h3 {
    ${typography.overlineText};
    margin-bottom: ${size.sm}px;
    text-transform: uppercase;
    font-weight: ${typography.weight.xbold};
    text-align: center;

    ${mediaQueries.forTabletHorizontalUp} {
      margin: 0 auto;
      text-align: left;
      padding: 0 0 ${size.lg}px ${size.xl3}px;
    }
  }

  h4 {
    ${typography.h3Text}
    font-weight: normal !important;
    margin-bottom: ${size.lg}px;
    text-align: center;

    ${mediaQueries.forTabletHorizontalUp} {
      margin: 0 auto;
      text-align: left;
      padding-left: ${size.xl3}px;
    }
  }
`;

export const SectionContainer = styled(Container)`
  display: flex;
  flex-direction: column;

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const TitleHeadline = styled.div`
  ${mediaQueries.forTabletHorizontalUp} {
    justify-content: left;
  }
`;

export const TextSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: column;
    flex: 0 0 auto;
    padding-top: ${size.xl3}px;
  }

  ${mediaQueries.forDesktopDown} {
    max-width: 600px;
  }
`;

export const List = styled.ul`
  padding: 0 ${size.xs1}px;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${size.md}px;

  ${mediaQueries.forTabletHorizontalUp} {
    padding: ${size.xl2}px;
  }
`;

export const ListItem = styled.li`
  ${typography.bodyTextSmall}
  display: flex;
  align-items: baseline;
  position: relative;
  padding-left: ${size.md}px;

  &::before {
    content: '';
    display: block;
    width: 9px;
    height: 8px;
    background-image: url(${checkMarkBullet});
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: ${size.xs1}px;
    position: absolute;
    left: 0;
    top: ${size.sm}px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    ${typography.bodyTextSmall}
  }
`;

export const ListItemLink = styled.a`
  ${typography.bodyTextSmall};
  color: ${colors.green5};
  text-decoration: none;
  font-weight: ${typography.weight.bold};
`;

export const ImageContainer = styled.div`
  padding: ${size.xl3}px ${size.xl3}px 0 ${size.xl3}px;
  display: flex;
  align-items: center;

  .desktop-image {
    display: none;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    .desktop-image {
      display: block;
      max-width: 400px;
    }

    .mobile-image {
      display: none;
    }
  }
`;
