import { H3, H4, useHooks } from '@everlywell/leaves';
import TrackVisibilityWrapper from 'components/TrackVisibilityWrapper';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { ImageType } from 'utils/types';

import * as S from './HealthPathContainer.styles';

export type HealthPathContainerProps = {
  content?: {
    title: string;
    headline: string;
    modalButtonText: string;
    mobileImages?: ImageType[];
    desktopImages?: ImageType[];
    listOfContentSections: Array<{
      title: string;
    }>;
  };
};

const HealthPathContainer = ({
  content,
}: HealthPathContainerProps): JSX.Element | null => {
  const { isMobile } = useHooks.useWindowSize();
  if (!content) {
    return null;
  }

  const {
    title,
    headline,
    modalButtonText,
    mobileImages,
    desktopImages,
    listOfContentSections,
  } = content;

  const trackLinkClick = () => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_LINK,
      data: {
        label: `${modalButtonText} - ${title}`,
      },
    });
  };

  return (
    <S.SectionWrapper>
      <TrackVisibilityWrapper
        label={ANALYTICS.PDP.HEALTH_PATH_SECTION}
        slug={title}
      >
        <S.SectionContainer data-testid="healthPathContainer">
          <S.TextSectionContainer>
            <S.TitleHeadline>
              <H3>{title}</H3>
              <H4>{headline}</H4>
            </S.TitleHeadline>
            {listOfContentSections.length ? (
              <S.List data-testid="healthPathList">
                {listOfContentSections.map((item, index) => {
                  const requiresLinkForText =
                    item.title.includes(modalButtonText);
                  const listItemWithLink = requiresLinkForText
                    ? item.title.split(modalButtonText)[0]
                    : '';
                  return (
                    <S.ListItem key={index}>
                      {requiresLinkForText ? (
                        <span>
                          {listItemWithLink}
                          <S.ListItemLink
                            data-testid="healthPathLink"
                            target={isMobile ? '_self' : '_blank'}
                            href="https://www.everlywell.com/control-membership"
                            onClick={trackLinkClick}
                          >
                            {modalButtonText}
                          </S.ListItemLink>
                        </span>
                      ) : (
                        item.title
                      )}
                    </S.ListItem>
                  );
                })}
              </S.List>
            ) : null}
          </S.TextSectionContainer>
          <S.ImageContainer data-testid="healthPathImage">
            {mobileImages && mobileImages[0] ? (
              <div>
                <GatsbyImage
                  className="mobile-image"
                  image={mobileImages[0].gatsbyImageData}
                  alt={mobileImages[0].description || ''}
                />
              </div>
            ) : null}

            {desktopImages && desktopImages[0] ? (
              <div>
                <GatsbyImage
                  className="desktop-image"
                  image={desktopImages[0].gatsbyImageData}
                  alt={desktopImages[0].description || ''}
                />
              </div>
            ) : null}
          </S.ImageContainer>
        </S.SectionContainer>
      </TrackVisibilityWrapper>
    </S.SectionWrapper>
  );
};

export default HealthPathContainer;
